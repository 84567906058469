import axios from "axios";
import axiosInstance from "../axiosInstance";
import {
  SET_ADDRESS_LIST,
  SET_ADD_ADDRESS,
  SET_ADD_BUSINESS,
  SET_ADD_PRODUCT_CATEGORY,
  SET_ADD_QR_CODE,
  SET_ADD_REWARD,
  SET_ADD_STAFF,
  SET_BUSINESS_LIST,
  SET_CATEGORY_LIST,
  SET_CLEAR_NOTIFICATION,
  SET_COUNTRY_LIST,
  SET_COUPON_APPLY,
  SET_COUPON_DELETE,
  SET_COUPON_LIST,
  SET_DASHBOARD_DETAILS,
  SET_DELETE_ADDRESS,
  SET_DELETE_BUSINESS,
  SET_DELETE_PRODUCT_CATEGORY,
  SET_DELETE_QR_CODE,
  SET_DELETE_REWARD,
  SET_DELETE_STAFF,
  SET_HOMEPAGE_DATA,
  SET_NOTIFICATION_ADD,
  SET_NOTIFICATION_LIST,
  SET_PRIVACY_POLICY_DATA,
  SET_PRODUCT_CATEGORY_LIST,
  SET_QR_CODE_DETAILS,
  SET_QR_CODE_LIST,
  SET_REVIEW_LIST,
  SET_REWARD_LIST,
  SET_SALES_LIST,
  SET_STAFF_LIST,
  SET_SUBSCRIPTION_CHARGE,
  SET_SUBSCRIPTION_LIST,
  SET_TERMS_DATA,
  SET_TRANSECTION_DETAILS,
  SET_UPDATE_PROFILE,
  SET_USER_DELETE,
  SET_USER_PROFILE,
} from "../types";
import { setBtnLoading, setPageLoading } from "./authActions";
import ErrorPopup from "../../popups/ErrorPopup";
import SuccessPopup from "../../popups/SuccessPopup";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("loyalty_token")}`,
  },
};

export const getUserProfileData = (navigate) => async (dispatch) => {
  dispatch(setPageLoading(true));
  try {
    const res = await axiosInstance.get("/user-profile", config);

    if (res.data.status) {
      dispatch({
        type: SET_USER_PROFILE,
        payload: res.data.data,
      });
      dispatch(setPageLoading(false));
    }
  } catch (error) {
    if (error?.response?.data?.message === "Unauthenticated.") {
      localStorage.clear();
      navigate("/login");
    }
    dispatch(setPageLoading(false));
  }
};

export const onUpdateProfile = (data) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/update-profile", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_UPDATE_PROFILE,
        payload: res.data,
      });
      dispatch(setBtnLoading(false));
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
    console.log(error);
  }
};

export const getCategoryList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/category-list", config);

    if (res.data.status) {
      dispatch({
        type: SET_CATEGORY_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getQrCodeList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/list-qrcode", config);

    if (res.data.status) {
      dispatch({
        type: SET_QR_CODE_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const onAddQrCode = (data, Popup) => async (dispatch) => {
  try {
    const res = await axiosInstance.post("/generate-qr", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_ADD_QR_CODE,
        payload: res.data,
      });
      Popup(<SuccessPopup Popup={Popup} message={res.data.message} />);
      dispatch(setBtnLoading(false));
    }
  } catch (error) {
    console.log(error);
    dispatch(setBtnLoading(false));
  }
};

export const onDeleteQrCode = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));

  try {
    const res = await axiosInstance.post("/delete-qr", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_DELETE_QR_CODE,
        payload: res.data,
      });
      dispatch(setBtnLoading(false));
      Popup();
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const getSalesList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/single-qr-code", config);

    if (res.data.status) {
      dispatch({
        type: SET_SALES_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getRewardList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/reward-list", config);

    if (res.data.status) {
      dispatch({
        type: SET_REWARD_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const onAddReward = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/add-reward", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_ADD_REWARD,
        payload: res.data,
      });
      dispatch(setBtnLoading(false));
      Popup(<SuccessPopup Popup={Popup} message={res.data.message} />);
    }
  } catch (error) {
    console.log(error);
    dispatch(setBtnLoading(false));
  }
};

export const onDeleteReward = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/remove-reward", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_DELETE_REWARD,
        payload: res.data,
      });
      Popup();
      dispatch(setBtnLoading(false));
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const getBusinessList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/business-list", config);

    if (res.data.status) {
      dispatch({
        type: SET_BUSINESS_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const onAddBusiness = (data, Popup) => async (dispatch) => {
  try {
    const res = await axiosInstance.post("/add-business", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_ADD_BUSINESS,
        payload: res.data,
      });
      dispatch(setBtnLoading(false));
      Popup(<SuccessPopup Popup={Popup} message={res.data.message} />);
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const onDeleteBusiness = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/delete-business", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_DELETE_BUSINESS,
        payload: res.data,
      });
      Popup();
      dispatch(setBtnLoading(false));
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const onContactUs =
  (data, setValues, setSuccessPopup) => async (dispatch) => {
    dispatch(setBtnLoading(true));
    try {
      const res = await axiosInstance.post("/contact-us", data, config);

      if (res.data.status) {
        setValues({
          email: "",
          name: "",
          phone_number: "",
          company_name: "",
          message: "",
        });
        dispatch(setBtnLoading(false));
        setSuccessPopup(
          <SuccessPopup Popup={setSuccessPopup} message={res.data.message} />
        );
      }
    } catch (error) {
      dispatch(setBtnLoading(false));
    }
  };

export const getHomePageData = () => async (dispatch) => {
  dispatch(setPageLoading(true));
  try {
    const res = await axiosInstance.get("/home-page-list?slug=test", config);

    if (res.data.status) {
      dispatch({
        type: SET_HOMEPAGE_DATA,
        payload: res.data.data,
      });
      dispatch(setPageLoading(false));
    }
  } catch (error) {
    dispatch(setPageLoading(false));
  }
};

export const getSubscriptionList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/subscriptionplan-list", config);

    if (res.data.status) {
      dispatch({
        type: SET_SUBSCRIPTION_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const onSubscriptionPlan = (data, navigate) => async (dispatch) => {
  try {
    const res = await axiosInstance.post("/subscription-charge", data, config);

    if (res.data.status) {
      navigate("/thank-you");
      dispatch({
        type: SET_SUBSCRIPTION_CHARGE,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const onCreateToken =
  (data, planDetails, planType, Popup, navigate, userDetails) =>
  async (dispatch) => {
    dispatch(setBtnLoading(true));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_STRIPE_URL}/v1/tokens`,
        data
      );

      if (planType === "newUser") {
        const newData = {
          token: res?.data?.id,
          amount: planDetails?.amount,
          subscription_id: planDetails?.id,
          email: userDetails?.email,
          name: userDetails?.name,
        };
        dispatch(onSubscriptionPlan(newData, navigate));
      } else if (planType === "businessPayment") {
        const formData = new FormData();
        formData.append("id", planDetails?.id);
        formData.append("business_name", planDetails?.business_name);
        formData.append("business_logo", planDetails?.business_logo);
        formData.append("address", planDetails?.address);
        formData.append("business_category", planDetails?.business_category);
        formData.append("state", planDetails?.state);
        formData.append("city", planDetails?.city);
        formData.append("zip_code", planDetails?.zip_code);
        formData.append("country_name", planDetails?.country_name);
        formData.append("token", res?.data?.id);
        formData.append("amount", 5);

        dispatch(onAddBusiness(formData, Popup));
      } else if (planType === "addressPayment") {
        const data = {
          id: planDetails?.id,
          business_id: planDetails?.business_id,
          business_category: planDetails?.business_category,
          address: planDetails?.address,
          state: planDetails?.state,
          city: planDetails?.city,
          zip_code: planDetails?.zip_code,
          country_name: planDetails?.country_name,
          token: res?.data?.id,
          amount: 5,
        };

        dispatch(onAddAddress(data, Popup));
      }
      dispatch(setBtnLoading(false));
    } catch (err) {
      Popup(
        <ErrorPopup
          popup={Popup}
          message={err?.response?.data?.error?.message}
        />
      );
      dispatch(setBtnLoading(false));
    }
  };

export const getReviewList = () => async (dispatch) => {
  dispatch(setPageLoading(true));
  try {
    const res = await axiosInstance.get("/review-list");

    if (res.data.status) {
      dispatch({
        type: SET_REVIEW_LIST,
        payload: res.data.data,
      });
      dispatch(setPageLoading(false));
    }
  } catch (error) {
    dispatch(setPageLoading(false));
  }
};

export const getNotificationList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/notification-list", config);

    if (res.data.status) {
      dispatch({
        type: SET_NOTIFICATION_LIST,
        payload: res.data.data.notificationData,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const onClearNotification = (setPopup, data) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/notification-clear", data, config);
    setPopup(
      <SuccessPopup
        Popup={setPopup}
        message="Notification cleared successfully"
      />
    );
    if (res.data.status) {
      dispatch({
        type: SET_CLEAR_NOTIFICATION,
        payload: res.data,
      });

      dispatch(setBtnLoading(false));
    }
  } catch (error) {
    console.log(error);
    dispatch(setBtnLoading(false));
  }
};

export const onCreateNotification = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/notification-add", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_NOTIFICATION_ADD,
        payload: res.data,
      });
      Popup(
        <SuccessPopup Popup={Popup} message="Notification added successfully" />
      );
      dispatch(setBtnLoading(false));
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const getAddressList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/address-list", config);

    if (res.data.status) {
      dispatch({
        type: SET_ADDRESS_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const onAddAddress = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/add-address", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_ADD_ADDRESS,
        payload: res.data,
      });
      dispatch(setBtnLoading(false));
      dispatch(getDashboardDetails());
      Popup(<SuccessPopup Popup={Popup} message={res.data.message} />);
    }
  } catch (error) {
    console.log(error);
    dispatch(setBtnLoading(false));
  }
};

export const onDeleteAddress = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/delete-address", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_DELETE_ADDRESS,
        payload: res.data,
      });
      Popup();
      dispatch(getDashboardDetails());
      dispatch(setBtnLoading(false));
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const getTransectionDetails = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/subscription-plan-active", config);

    if (res.data.status) {
      dispatch({
        type: SET_TRANSECTION_DETAILS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDashboardDetails = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/dashboard-details", config);

    if (res.data.status) {
      dispatch({
        type: SET_DASHBOARD_DETAILS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCouponList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/coupon-list", config);

    if (res.data.status) {
      dispatch({
        type: SET_COUPON_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const onCouponApply = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/coupon-apply", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_COUPON_APPLY,
        payload: res.data,
      });
      dispatch(setBtnLoading(false));
      Popup(<SuccessPopup Popup={Popup} message={res.data.message} />);
    }
  } catch (err) {
    Popup(<ErrorPopup popup={Popup} message={err?.response?.data?.message} />);
    dispatch(setBtnLoading(false));
  }
};

export const onDeleteCoupon = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/delete-coupon", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_COUPON_DELETE,
        payload: res.data,
      });
      dispatch(setBtnLoading(false));
      Popup();
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const qrCodeScannerUser = (data, navigate) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/qr-code-scanner", data, config);

    localStorage.setItem("qrData", JSON.stringify(res.data.data));
    navigate("/add-to-wallet");

    // if (res.data.status) {
    //   window.location.href = res.data.data.url;
    // }
    dispatch(setBtnLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setBtnLoading(false));
  }
};

export const onUserSoftDelete = (Popup, data) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/user-soft-delete", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_USER_DELETE,
        payload: res.data,
      });
      Popup();
      dispatch(setBtnLoading(false));
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const getCountryList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/country-list", config);

    if (res.data.status) {
      dispatch({
        type: SET_COUNTRY_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getStaffList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/staff-list", config);

    if (res.data.status) {
      dispatch({
        type: SET_STAFF_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const onAddStaff = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/add-staff", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_ADD_STAFF,
        payload: res.data,
      });
      dispatch(setBtnLoading(false));
      Popup(<SuccessPopup Popup={Popup} message="Staff add successfully" />);
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const onDeleteStaff = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/delete-staff", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_DELETE_STAFF,
        payload: res.data,
      });
      Popup();
      dispatch(setBtnLoading(false));
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const getQrCodeDetails = (token) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/qr-detail?qr_token=${token}`, config);
console.log(res.data.data);
    if (res.data.status) {
      dispatch({
        type: SET_QR_CODE_DETAILS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getProductCategoryList = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/product-category-list", config);

    if (res.data.status) {
      dispatch({
        type: SET_PRODUCT_CATEGORY_LIST,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const onAddProductCategory = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post("/product-category-add", data, config);

    if (res.data.status) {
      dispatch({
        type: SET_ADD_PRODUCT_CATEGORY,
        payload: res.data,
      });
      dispatch(setBtnLoading(false));
      Popup(
        <SuccessPopup
          Popup={Popup}
          message="Product category add successfully"
        />
      );
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const onDeleteProductCategory = (data, Popup) => async (dispatch) => {
  dispatch(setBtnLoading(true));
  try {
    const res = await axiosInstance.post(
      "/product-category-delete",
      data,
      config
    );

    if (res.data.status) {
      dispatch({
        type: SET_DELETE_PRODUCT_CATEGORY,
        payload: res.data,
      });
      Popup();
      dispatch(setBtnLoading(false));
    }
  } catch (error) {
    dispatch(setBtnLoading(false));
  }
};

export const getPrivacyPolicyData = (slug) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/pages?slug=${slug}`, config);

    if (res.data.status) {
      dispatch({
        type: SET_PRIVACY_POLICY_DATA,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const getTermsConditionData = (slug) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/pages?slug=${slug}`, config);

    if (res.data.status) {
      dispatch({
        type: SET_TERMS_DATA,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
